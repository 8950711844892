const tableData = [
    {
      name: 'First training',
      region: 'Littoral',
      city: 'Douala',
      institution: 'Laboratoire ',
      phone: '675636363',
      email: 'joel@admin.com',
      trainingPeriod:'2021/02/03 - 2021/03/03',

      },{
      name: 'First training',
      region: 'Littoral',
      city: 'Douala',
      institution: 'Laboratoire ',
      phone: '675636363',
      email: 'joel@admin.com',
     trainingPeriod:'2021/02/03 - 2021/03/03',

      },{
      name: 'First training',
      region: 'Center',
      city: 'Bafia',
      institution: 'Laboratoire ',
      phone: '675636363',
      email: 'joel@admin.com',
      trainingPeriod:'2021/02/03 - 2021/03/03',

      },{
      name: 'First training',
      region: 'Littoral',
      city: 'Douala',
      institution: 'Laboratoire ',
      phone: '675636363',
      email: 'joel@admin.com',
      trainingPeriod:'2021/02/03 - 2021/03/03',

      },{
      name: 'First training',
      region: 'Littoral',
      city: 'Douala',
      institution: 'Laboratoire ',
      phone: '675636363',
      email: 'joel@admin.com',
     trainingPeriod:'2021/02/03 - 2021/03/03',

      },

];

export { tableData };
