<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All Trainings</h4>
                            </div>
                            <div class="col-md-9">
                            </div>
                            <div class="col-md-1">
                                <b-button variant="success">
                                    <i class="ri-file-excel-2-line align-middle mr-2"></i> Export
                                </b-button>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="{ }">
                                    <b-button class="actionBut" variant="info" v-b-tooltip.hover title="View Details" href="">
                                        <i class="ri-eye-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="success" href="edit-training" v-b-tooltip.hover title="Edit">
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="danger"   v-b-tooltip.hover title="Delete">
                                        <i class="ri-delete-bin-2-fill align-middle " ></i>
                                    </b-button>
                                    <!--                                    <span><b-btn @click="editItem(item)">Edit</b-btn></span>-->


                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Layout>

</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";

    import { tableData } from "./dataTraining";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View Trainings",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {

                tableData: tableData,
                title: "View all Trainings",
                items: [
                    {
                        text: "CQI",
                        href: "/"
                    },
                    {
                        text: "View Trainings",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "",
                sortDesc: false,
                fields: [
                    { key: "name", sortable: true },
                    { key: "region", sortable: true },
                    { key: "city", sortable: true },
                    { key: "institution", sortable: true },
                    { key: "phone", sortable: true },
                    { key: "email", sortable: true },
                    // { key: "email", sortable: true },
                    // { key: "facilityEmail", sortable: true },
                    { key: "trainingPeriod", sortable: true },
                    { key: "actions", sortable: false },
                ],



            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableData.length;
            }
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;
        },
        methods: {
            // editItem(item) { console.log(item); },
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            }
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left: 15px;
    }

</style>
